import * as React from "react"
import { graphql } from "gatsby"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

import Page from "src/components/Page"
import NavBar from "src/components/NavBar"
import Section from "src/components/Section"

export const query = graphql`
  query DefaultTemplate($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: $slug } } }) {
      nodes {
        html
        excerpt
        frontmatter {
          title
          subtitle
        }
      }
    }
  }
`

const DefaultTemplate: React.FC<{ data: GatsbyTypes.DefaultTemplateQuery }> = ({
  data
}) => {
  const pageData = data?.allMarkdownRemark?.nodes[0]

  return (
    <Page>
      <NavBar />
      <Section>
        <h1>{pageData?.frontmatter?.title}</h1>
        <h4>{pageData?.frontmatter?.subtitle}</h4>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: pageData?.html || "" }} />
      </Section>
    </Page>
  )
}

export default DefaultTemplate
